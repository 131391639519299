import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import Stack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import moment from "moment";
import { getbatchWeighments } from "networks/services";
import { useLoading } from "context/loading.context";
import * as XLSX from 'xlsx';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import BatchTableData from "./data";

export default function BatchWeighments() {
    const { tableHeaders, formtable } = BatchTableData();
    const { showLoader, hideLoader } = useLoading();
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [batch, setBatch] = useState([]);

    const exportToExcel = (data, filename) => {
        const wb = XLSX.utils.book_new();
        for (const sheetName in data) {
            const ws = XLSX.utils.json_to_sheet(data[sheetName]);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        }
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    const getAverage = (arr) => {
        if (arr.length === 0) {
            return 0; // To handle the case where the array is empty
        }
        const sum = arr.reduce((acc, value) => acc + value, 0);
        return sum / arr.length;
    };

    const formBatchTemplateExcel = (data, filename) => {
        let groupedData = {};

       
        data.forEach((dt) => {
            if (!groupedData[dt.UnitWeight]) {
                groupedData[dt.UnitWeight] = [];
            }
            groupedData[dt.UnitWeight].push(dt);
        });

        let sheets = {};

        // Process each group
        for (let unitWeight in groupedData) {
            let group = groupedData[unitWeight];
            let res = [];
            let total_avg = [];

            group.forEach((dt) => {
                let temp = {
                    Device: dt.Device,
                    Weighment_Key: dt.WeighmentKey,
                    EntryTime: moment.unix(dt.EntryTime).format("DD-MM-YYYY HH:mm:ss"),
                    UnitWeight: dt.UnitWeight,
                    Average: getAverage(dt.SampleWeights)
                };

                total_avg.push(getAverage(dt.SampleWeights));

                for (let tp in dt.SampleWeights) {
                    temp[`Sample ${parseInt(tp) + 1}`] = dt.SampleWeights[tp];
                }
                res.push({ ...temp });
            });

            res.push({
                EntryTime: `Total Average for UnitWeight ${unitWeight}`,
                Average: getAverage(total_avg)
            });

            sheets[`UnitWeight ${unitWeight}`] = res;
        }

        exportToExcel(sheets, filename);
    };

    const fetchBatch = () => {
        let param = ``;
        if (from) {
            param += `EntryTime_gte=${moment(from).startOf("Day").unix()}`;
        }
        if (to) {
            if (param.length > 0) param += `&`;
            param += `EntryTime_lte=${moment(to).endOf("Day").unix()}`;
        }
        console.log("Framed param", param);
        showLoader();
        getbatchWeighments(param)
            .then((resp) => {
                setBatch(resp.data);
                hideLoader();
            })
            .catch((err) => {
                hideLoader();
                console.log(err);
            });
    };

    useEffect(() => {
        fetchBatch();
    }, [from, to]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                mx={2}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Batch Weighments
                </MDTypography>
            </MDBox>
            <Stack
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <MDBox pt={3} pr={3}>
                    <MDButton variant="gradient" color="warning" onClick={() => {
                        formBatchTemplateExcel(batch, moment().unix());
                    }}>
                        <Icon>download</Icon>&nbsp;
                        Export
                    </MDButton>
                </MDBox>
            </Stack>
            <MDBox pt={3} px={2} py={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} xl={2}>
                        <MDInput
                            fullWidth
                            type="date"
                            label="From"
                            value={moment(from).format("YYYY-MM-DD")}
                            onChange={(event) => {
                                setFrom(event.target.valueAsDate);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} xl={2}>
                        <MDInput
                            fullWidth
                            type="date"
                            label="To"
                            value={moment(to).format("YYYY-MM-DD")}
                            onChange={(event) => {
                                setTo(event.target.valueAsDate);
                            }}
                        />
                    </Grid>
                </Grid>
            </MDBox>
            <DataTable
                table={{
                    columns: tableHeaders,
                    rows: formtable(batch)
                }}
                noEndBorder
                canSearch
            />
        </DashboardLayout>
    );
}
