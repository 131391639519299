import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";



import Icon from "@mui/material/Icon";
import DataTable from "examples/Tables/DataTable";
import { useState } from "react";
import MouldTable from "./data";

import MDBox from "components/MDBox";
import Stack from '@mui/material/Stack';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { useLoading } from "context/loading.context";
import { getmouldWeighments } from "networks/services"
import { useEffect } from "react";

import * as XLSX from 'xlsx';

export default function MouldWeighments() {


    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [mould, setMould] = useState([]);
    const { showLoader, hideLoader } = useLoading();

    const navigate = useNavigate();
    const { tableHeaders, formtable, sampleRows } = MouldTable();



    const exportToExcel = (data, filename) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };


    const formBatchTemplateExcel = async (data, filename) => {
        let res = [];
        let total = { EntryTime: "Total", Weighment: 0 };
        for (let dt of mould) {
            let temp = {
                Device: dt.Device,
                WeighmentKey: dt.WeighmentKey,
                Product: dt.Product,
                EntryTime: moment.unix(dt.EntryTime).format("DD-MM-YYYY HH:mm:ss"),
                Weighment: `${dt.Weighment}`
                // sampleWeights: dt.SampleWeights.join(", "),
            };
            total.Weighment += parseFloat(dt.Weighment);
            res.push({ ...temp });
        }
        res.push(total);
        exportToExcel(res, filename);
    }


    const fetchMould = () => {
        let param = ``;
        if (from) {
            param += `EntryTime_gte=${moment(from).startOf("Day").unix()}`
        }
        if (to) {
            if (param.length > 0) param += `&`
            param += `EntryTime_lte=${moment(to).endOf("Day").unix()}`
        }
        console.log("Framed param", param);
        showLoader();
        getmouldWeighments(param).then((resp) => {
            setMould(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })
    }

    useEffect(() => {
        fetchMould();
    }, [setMould, from, to])

    //Filter operation:


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox
                mx={2}

                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    MouldWeighments
                </MDTypography>
            </MDBox>
            <Stack
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <MDBox pt={3}>
                    <MDButton variant="gradient" color="warning" onClick={() => {
                        formBatchTemplateExcel(formtable(mould), moment().unix());
                    }}>
                        <Icon>download</Icon>&nbsp;
                        Export
                    </MDButton>
                </MDBox>
            </Stack>
            <MDBox pt={3} px={2} py={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} xl={2}>
                        <MDInput fullWidth type="date" label={"From"} value={moment(from).format("YYYY-MM-DD")} onChange={(event) => {
                            setFrom(event.target.valueAsDate);
                        }}></MDInput>
                    </Grid>
                    <Grid item xs={12} md={4} xl={2}>
                        <MDInput fullWidth type="date" label={"To"} value={moment(to).format("YYYY-MM-DD")} onChange={(event) => {
                            setTo(event.target.valueAsDate);
                        }}></MDInput>
                    </Grid>

                </Grid>
            </MDBox>

            <DataTable
                table={{
                    columns: tableHeaders,
                    rows: formtable(mould)
                }}
                noEndBorder
                canSearch
            />
        </DashboardLayout>
    );
}


