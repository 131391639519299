import axios from "axios";
// export const FILE_API_ENDPOINT = "http://18.61.111.212:3025/";
export const FILE_API_ENDPOINT = "https://dev.iggietech.in/";
export const API_ENDPOINT = `${FILE_API_ENDPOINT}medimix/`;
export const getAxiosInstance = () => {
    var axiosInstance = axios.create({
        timeout: 1000 * 60
    });
    axiosInstance.defaults.baseURL = API_ENDPOINT;
    return axiosInstance;
};

var axiosInstance = getAxiosInstance();
axiosInstance.interceptors.request.use(
    function (config) {
        console.log(config.url);
        if (config.url === "users/login") {
            return config;
        } else {
            let authToken = window.localStorage.getItem("jwt");
            console.log("Checking", authToken);
            console.log(authToken);
            config.headers["Authorization"] = `Bearer ${authToken}`;
        }
        return config;
    },
    function (error) {
        console.log(error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error.response.status);
    if (error.response.status === 401) {
        window.localStorage.clear();
        window.location.reload();
    }
    return Promise.reject(error);
});



export const fetchLogin = (username, password) => {
    return axiosInstance.post(
        "users/login",
        {
            username: username,
            password: password
        },
        {}
    );
};
export const getbatchWeighments = (param) => {
    return axiosInstance.get(`BatchWeighments${param ? `?${param}` : ``}`);
}

export const getmouldWeighments = (param) => {
    return axiosInstance.get(`MouldWeighments${param ? `?${param}` : ``}`);
}

