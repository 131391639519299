import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/Medimix_img.jpg";
import { AuthContext } from "context/auth.context";
import { fetchLogin } from "networks/services";

function Basic() {
  const { setLogin, setUserDoc } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State to keep track of errors

  const login = () => {
    fetchLogin(username, password)
      .then((resp) => {
        console.log(resp.data);
        setLogin(resp.data.token);
        setUserDoc(resp.data);
        setError(""); 
      })
      .catch((err) => {
        console.log(err);
        setError("Username or password is incorrect"); 
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            {error && (
              <MDBox mb={2}>
                <MDTypography  color="error" sx={{fontSize:"14px"}}>
                  {error}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="success" fullWidth onClick={login}>
               Enter
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              
            
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
