import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, Switch, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "context/auth.context";
import { auth } from "routes";
import routes from "routes";


export default function MainRoutes() {
  const { authenticated, userDoc } = useContext(AuthContext);
  const location = useLocation();
  useEffect(() => {
    // This callback will be executed on every route change
    console.log('Route has changed!');
    // trackRoute(location.pathname);
  }, [location.pathname]); // Empty dependency array means it runs only on mount and unmount

   if (!authenticated) {
    return (
      <Routes>
        {auth.map((route) => {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        })}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />;
      </Routes>
    )
  }
    return (
      <Routes>
        {routes.map((route) => {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        })}
        return <Route path="*" element={<Navigate to="/mouldweighment" />} />;
      </Routes>
    );
  }
