const moment = require("moment");
export default function MouldTable() {
    const formtable = (data) => {
        let result = [];
        for (let dt of data) {
            result.push({
                device: dt.Device,
                WeighmentKey: dt.WeighmentKey,
                product: dt.Product,
                entryTime: moment.unix(dt.EntryTime).format("DD-MM-YYYY HH:mm:ss"),
                weighment: `${dt.Weighment} kg`
            });
        }
        return result;
    };

    return {
        formtable,
        tableHeaders: [
            { Header: "device", accessor: "device", align: "center" },
            { Header: "WeighmentKey", accessor: "WeighmentKey", align: "center" },
            { Header: "product", accessor: "product", align: "center" },
            { Header: "entryTime ", accessor: "entryTime", align: "center" },
            { Header: "weighment", accessor: "weighment", align: "center" },
        ],

        sampleRows: [
            {

                product: "2414",
                WeighmentKey: 21,
                device: "08D1F9929539",
                entryTime: "15-05-2024",
                weighment: "0.50",

            }
        ]
    };
}
