const moment = require("moment");
export default function BatchTableData() {
    const formtable = (data) => {
        let result = [];
        for (let dt of data) {
            result.push({
                device: dt.Device,
                weighmentKey: dt.WeighmentKey,
                product: dt.Product,
                entryTime: moment.unix(dt.EntryTime).format("DD-MM-YYYY HH:mm:ss"),
                unitWeight: dt.UnitWeight,
                sampleWeights: dt.SampleWeights.join(", "),

            });
        }
        return result;
    };

    return {
        formtable,
        tableHeaders: [
            { Header: "device", accessor: "device", align: "center" },
            { Header: "WeighmentKey", accessor: "weighmentKey", align: "center" },
            { Header: " entryTime ", accessor: "entryTime", align: "center" },
            { Header: "UnitWeight", accessor: "unitWeight", align: "center" },
            { Header: "SampleWeightscount", accessor: "sampleWeights", align: "center" },



        ],
        sampleRows: [
            {

                product: "2414",
                WeighmentKey: 21,
                device: "08D1F9929539",
                entryTime: "15-05-2024",
                UnitWeight: "0.50",
                SampleWeights: 5
            },


        ]

    };
}
